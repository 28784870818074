import React, { Component } from 'react'
import 'firebase/firestore'
import 'firebase/auth'
import './RegisterPage.css'
import firebase from 'firebase/app'

class RegisterPage extends Component{
  unsubscribe
  constructor(props){
    super(props)
    this.props = props
    this.state =
    {
      name: "testo",
      email: "0@gmail.com",
      password: "00000000",
      quote: "Lame gang",
      registerError: <p></p>
    }

  }

  changeEmail = (evt) => {
    this.setState({
      ...this.state,
      email: (evt.target.value)
    })
  }

  changePassword = (evt) => {
    this.setState({
      ...this.state,
      password: (evt.target.value)
    })
  }

  changeName = (evt) => {
    this.setState({
      ...this.state,
      name: (evt.target.value)
    })
  }

  changeQuote = (evt) => {
    this.setState({
      ...this.state,
      quote: (evt.target.value)
    })
  }

  firebaseSignUp = () => {
    firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).catch((error) => {
      this.setState({
        ...this.state,
        registerError: <p style={{color:'red'}}>Error: {error.message}</p>
      })
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.firestore().collection("users").doc(user.uid).set({
          name: this.state.name,
          id: user.uid,
          quote: this.state.quote,
          email: this.state.email,
          admin: false
      })
      this.setState({
        ...this.state,
        registerError: <p style={{color:'green'}}>Success: Signed up!</p>
      })
    // User is signed in.
      } else {
    // No user is signed in.
        }
      });
  }

  render() {

    return (
      <div align="Center">
        <div className="LoginContainer">
          Name: <input className="RegisterInput" type="text" placeholder="Your display name" onChange={this.changeName}></input>
          <br/>
          Your personal catchphrase: <input className="RegisterInput" type="text" placeholder="Can't be changed later!" onChange={this.changeQuote}></input>
          <br/>
          Email: <input className="RegisterInput" type="text" placeholder="Valid email address" onChange={this.changeEmail}></input>
          <br/>
          Password <input className="RegisterInput" type="password" placeholder="dw i can't see what u enter here" onChange={this.changePassword}></input>
          <br/>
          <br/>
          {this.state.registerError}
          <button className="btn" onClick = {this.firebaseSignUp}>Sign Up</button>
        </div>
      </div>

    )
  }


}
export default RegisterPage;
