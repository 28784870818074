import Matter from 'matter-js'

let Engine = Matter.Engine,
  World = Matter.World,
  Bodies = Matter.Bodies,
  Constraint = Matter.Constraint

const engine = Engine.create()
  const world = engine.world

const CreatureSim = (p5) => {
  const screenWidth = p5.windowWidth / 1.3
  const screenHeight = p5.windowHeight / 1.3
  const showEyes = true
  window.p5 = p5
  let creatures = []


  let exampleDna = [
    [0, 1, 1, 1, 1, 1, 1, 0],
    [0, 1, 1, 1, -2, 1, 1, 0],
    [-1, 1, 0, 1, 1, 0, 1, -1],
    [-1, 1, 1, 1, 1, 1, 1, -1],
    [-1, 0, 1, 1, 1, 1, 0, -1],
    [0, 0, -1, 1, 1, -1, 0, 0],
    [0, 0, -1, 0, 0, -1, 0, 0],
    [0, 0, -1, 0, 0, -1, 0, 0]
  ]
  let exampleDna2 = [
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, -1, -1, -1, -1, -1, 0, 0],
    [0, -1, 1, 1, 1, -1, 0, 0],
    [0, -1, 1, -1, 1, -1, 0, 0],
    [0, -1, 1, 1, 1, -1, 0, 0],
    [0, -1, -1, -1, -1, -1, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0]
  ]
  p5.setup = () => {

    p5.createCanvas(screenWidth, screenHeight)
    p5.frameRate(60)

    Engine.run(engine)

    let ground = Bodies.rectangle(screenWidth/2, screenHeight+250, screenWidth*2, 500, {isStatic: true})
    let roof = Bodies.rectangle(screenWidth / 2, 0-250, screenWidth*2, 500, { isStatic: true })
    let wallLeft = Bodies.rectangle(-250, screenHeight/2, 500, screenHeight, { isStatic: true })
    let wallRight = Bodies.rectangle(screenWidth+250, screenHeight / 2, 500, screenHeight, { isStatic: true })
    World.add(world, ground);
    World.add(world, wallLeft)
    World.add(world, wallRight)
    World.add(world, roof)

    
  }


let angle = 0
  p5.draw = () => {
    angle += 0.1
    p5.background('#222025')
    for (let i = 0; i < creatures.length; i++) {
      // creature.applyForce(0.0002, 0)
      if (true){
        creatures[i].draw()
      }
      
      creatures[i].update()
    }
    // p5.rect(400, 200, 50, 50)
    p5.push()
    p5.translate(400, 200)
    p5.rotate(angle)
    p5.rectMode(p5.CENTER)
    p5.rect(0, 0, 50, 50)
    p5.pop()

  }

  class Creature {
    constructor (pos, dna) {
      this.dna = dna
      this.body = dna
      this.cellSize = 15
      this.cells = []
      this.cellPos = {}
      this.muscles = []
      this.pos = pos
      this.size = 100
      this.gravity = 1.1
      this.mass = 1 // should be number of flesh blocks later
      this.feelers = []
      this.feelerLength = 500

      this.brain = null
      // this.brainX = 4    // pick random spot on grid for brain in the future.
      // this.brainY = 1   // eg this.body[1][4] will access brain

      this.constructBody()
      this.addFeelers(1)
      let rigidBodyArray = [this.brain.body]
      for (let cell of this.cells) {rigidBodyArray.push(cell.body)}
      
      this.rigidbody = Matter.Body.create({
        parts: rigidBodyArray,
        friction: 0.02
      })
      // Matter.Body.setAngle(this.brain.body, 1.2)
      // Matter.Body.setParts(this.brain.body, [this.brain.body, this.cells[0].body])
      World.add(world, this.rigidbody)

      // Matter.Body.setParts(this.brain.body, [this.brain.body, this.cells[29].body, this.cells[30].body])

      // console.log(this.brain.body.position)
     
    }

    addFeelers(num) {
      this.feelers.push({
        offset: -Math.PI/4,
        x1: this.brain.body.position.x + this.cellSize / 2,
        x2: this.brain.body.position.x + this.cellSize / 2 + this.feelerLength * Math.cos(this.brain.body.angle - Math.PI / 4),
        y1: this.brain.body.position.y + this.cellSize / 2,
        y2: this.brain.body.position.y + this.cellSize / 2 + this.feelerLength * Math.sin(this.brain.body.angle - Math.PI / 4),
        distance: 0
        })
    }

    createConstraints(type, thisCell, i, j, stiff) {
      if (i > 0) { // check up
        if (this.body[i - 1][j] === type) {
          World.add(world, Constraint.create({
            bodyA: thisCell,
            bodyB: this.cellPos[(i - 1).toString() + (j).toString()].body,
            stiffness: stiff,
          }))
        }
      }
      if (j > 0) { // check left
        if (this.body[i][j - 1] === type) {
          World.add(world, Constraint.create({
            bodyA: thisCell,
            bodyB: this.cellPos[(i).toString() + (j - 1).toString()].body,
            stiffness: stiff,
          }))

        }
      }
      if (j > 0 && i > 0) { // check up and left
        if (this.body[i - 1][j - 1] === type) {
          World.add(world, Constraint.create({
            bodyA: thisCell,
            bodyB: this.cellPos[(i - 1).toString() + (j - 1).toString()].body,
            stiffness: stiff,
          }))

        }
      }
      if (j < 8 && i > 0) { // check up and left
        if (this.body[i - 1][j + 1] === type) {
          World.add(world, Constraint.create({
            bodyA: thisCell,
            bodyB: this.cellPos[(i - 1).toString() + (j + 1).toString()].body,
            stiffness: stiff,
          }))

        }
      }
    }
    constructBody () {
      for (let i = 0; i < this.body.length; i++) {
        for (let j = 0; j < this.body[i].length; j++) {
          switch (this.body[i][j]) {
            case 1:
              let newCell = new Flesh(this.pos.x + j * this.cellSize, this.pos.y + i * this.cellSize, this.cellSize, this.cellSize, {
                friction: 0.1,
                restitution: 0.4
              }, [j, i])
              // this.createConstraints(1, newCell.body, i, j, 1.2)
              // this.createConstraints(-1, newCell.body, i, j, 1.2)
              this.cells.push(newCell)
              this.cellPos[i.toString() + j.toString()] = newCell    
              console.log(this.cellPos)
              break
            case -2:
              let newBrain = new Brain(this.pos.x + j * this.cellSize, this.pos.y + i * this.cellSize, this.cellSize, this.cellSize, {
                friction: 0.1,
                restitution: 0.4
              }, [j, i])
              // this.createConstraints(1, newBrain.body, i, j, 1.2)
              // this.createConstraints(-1, newBrain.body, i, j, 1.2)
              // this.cells.push(newBrain)
              this.cellPos[i.toString() + j.toString()] = newBrain
              this.brain = newBrain
              console.log(this.cellPos)
              break
            case -1:
              let newCellMuscle = new Muscle(this.pos.x + j * this.cellSize, this.pos.y + i * this.cellSize, this.cellSize, this.cellSize, {
                friction: 0.1,
                restitution: 0.4
              })
              // this.createConstraints(1, newCellMuscle.body, i, j, 0.0)
              this.createConstraints(-1, newCellMuscle.body, i, j, 0.0)
              // this.cells.push(newCellMuscle)
              this.muscles.push(newCellMuscle)
              this.cellPos[i.toString() + j.toString()] = newCellMuscle
              break
            default:

          }
        }
      }

    }

    update () {

      for (let muscle of this.muscles) {
        muscle.force()
      }
      this.feelers[0] = {
        offset: -Math.PI / 4,
        x1: this.brain.body.position.x + this.cellSize / 2,
        x2: this.brain.body.position.x + this.cellSize / 2 + this.feelerLength * Math.cos(this.brain.body.angle - Math.PI / 4),
        y1: this.brain.body.position.y + this.cellSize / 2,
        y2: this.brain.body.position.y + this.cellSize / 2 + this.feelerLength * Math.sin(this.brain.body.angle - Math.PI / 4),
        distance: 0
      }

    }

    draw () {
      for (let cell of this.cells) {
        cell.draw()
      }
      for (let muscle of this.muscles) {
        muscle.draw()
      }
      this.brain.draw()
      if (showEyes){
        for (let feeler of this.feelers) {
          p5.stroke('red')
          p5.line(feeler.x1, feeler.y1, feeler.x2, feeler.y2)
        }
      }
    }
  }

  class Flesh {
    constructor(x, y, w, h, options, group) {
      this.x = x
      this.y = y
      this.w = w
      this.h = h
      this.options = options
      this.body = Bodies.rectangle(this.x, this.y, this.w, this.h, this.options)
      // this.body.collisionFilter.group = -1
      // World.add(world, this.body)
    }

    draw() {
      let pos = this.body.position
      let angle = this.body.angle
      p5.push()
      p5.translate(pos.x, pos.y)
      p5.rotate(this.body.parent.angle)
      p5.rectMode(p5.CENTER)
      p5.stroke('#cc3232')
      p5.fill('#cc5777')
      p5.rect(0, 0, this.w, this.h)
      p5.pop()
    }    
  }
  class Brain {
    constructor(x, y, w, h, options, group) {
      this.x = x
      this.y = y
      this.w = w
      this.h = h
      this.options = options
      this.body = Bodies.rectangle(this.x, this.y, this.w, this.h, this.options)
      // this.body.collisionFilter.group = -1
      // World.add(world, this.body)
    }

    draw() {
      let pos = this.body.position
      let angle = this.body.angle
      p5.push()
      p5.translate(pos.x, pos.y)
      p5.rotate(this.body.parent.angle)
      p5.rectMode(p5.CENTER)
      p5.stroke('#7c3232')
      p5.fill('#5c3737')
      p5.rect(0, 0, this.w, this.h)
      p5.pop()
    }
  }
  class Muscle {
    constructor(x, y, w, h, options, group, pos) {
      this.x = x
      this.y = y
      this.w = w
      this.h = h
      this.options = options
      this.pos = pos
      this.body = Bodies.rectangle(this.x, this.y, this.w, this.h, this.options)
      // this.body.collisionFilter.group = -1
      World.add(world, this.body)
    }

    force() {
      Matter.Body.applyForce(this.body, this.body.position, { x: 0.002 * (Math.random() * 2 - 1), y: 0.002 * (Math.random() * 2 - 1)})
    }

    draw() {
      let pos = this.body.position
      let angle = this.body.angle
      p5.push()
      p5.translate(pos.x, pos.y)
      p5.rotate(angle)
      p5.rectMode(p5.CENTER)
      p5.stroke('#cc3232')
      p5.fill('#ff5757')
      p5.rect(0, 0, this.w, this.h)
      p5.pop()
    }
  }

  creatures.push(new Creature({x: 500, y: 0}, exampleDna))

}

export default CreatureSim