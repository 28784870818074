import React, { Component } from 'react'
import P5Wrapper from 'react-p5-wrapper';
import Particles from './Particles';
import "../index.css"
class Home extends Component {
  constructor(props){
    super(props)

    this.state={
      points: null,
      settingPoints: 0
    }
  }


  // addPoints = e => {
  //   if (this.state.points || this.state.points === 0) {
  //     firebase.firestore().collection("points").doc("points").update({
  //       num_points: this.state.points + 1
  //     })
  //   }
  // }
  //
  // removePoints = e => {
  //   if (this.state.points) {
  //     firebase.firestore().collection("points").doc("points").update({
  //       num_points: this.state.points - 1
  //     })
  //   }
  // }

//inside render
//   firebase.firestore().collection("points").get().then((querySnapshot) => {
//   querySnapshot.forEach((doc) => {
//       this.setState({
//         ...this.state,
//         points: doc.data().num_points,
//       })
//   });
// });
  render(){





    return(
      <div>
      <P5Wrapper sketch={Particles} signal={this.state.create_snake_signal}></P5Wrapper>
        {/* <h1>Home pages are dying</h1>
        <p style={{textAlign:'center'}}>
          <i>direct all enquiries to bjbraham@gmail.com</i>
        </p> */}
      </div>
    )
  }
}


export default Home;
