import React, { Component } from 'react'
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { Redirect } from "react-router-dom";
import './LoginPage.css'

class LoginPage extends Component{
  unsubscribe
  constructor(props){
    super(props)
    this.props = props
    this.state =
    {
      redirect: null,
      email: "0@gmail.com",
      password: "00000000",
      loginError: <p></p>
    }

  }

  changeEmail = (evt) => {
    this.setState({
      ...this.state,
      email: (evt.target.value)
    })
  }

  changePassword = (evt) => {
    this.setState({
      ...this.state,
      password: (evt.target.value)
    })
  }

  firebaseSignIn = () => {
    firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch((error) => {
      this.setState({
        ...this.state,
        loginError: <p style={{"color":"red"}}>Invalid Username/Password</p>,
      })
    });
    this.unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          ...this.state,
          loginError: <p style={{"color":"green"}}>Signed in successfully.</p>,
        })
        // If the user is signed in - we can set these variables. Only use as needed.
        // var displayName = user.displayName;
        // var email = user.email;
        // var emailVerified = user.emailVerified;
        // var photoURL = user.photoURL;
        // var isAnonymous = user.isAnonymous;
        // var uid = user.uid;
        // var providerData = user.providerData;
      }});
  }

  handleKeyPress = (target) => {
    if(target.charCode===13){
      this.firebaseSignIn()
    }
  }

  registerRedirect = () => {
    this.setState({
      ...this.state,
      redirect: (<Redirect to="/register"/>)
    })
  }


  render() {
    return (
      <div align="Center">
        <div className="LoginContainer">
          Email: <input className="LoginInput" type="text" onChange={this.changeEmail}></input>
          <br/>
          Password <input className="LoginInput" type="password" onChange={this.changePassword} onKeyPress={this.handleKeyPress}></input>
          <br/>
          <br/>
          {this.state.loginError}
          <button className="btn waves-light deep-purple darken-4 btn" onClick = {this.firebaseSignIn}>Sign In</button>
          <br/>
          <br/>
          Don't have an account?
          <br/>
          <button className="btn waves-light deep-purple darken-4 btn" onClick = {this.registerRedirect}>Click to Sign Up</button>
          {this.state.redirect}
        </div>
      </div>

    )
  }


}
export default LoginPage
