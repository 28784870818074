import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import firebase from 'firebase/app'
import "../index.css"


class NavBar extends Component {
  constructor(props){
    super(props)
    this.props = props
    this.state =
    {
      display: <li>Loading..</li>
    }

  }

  signOut = e => {
    firebase.auth().signOut().then(function() {
  // Sign-out successful.
      }).catch(function(error) {
        // An error happened.
    });
  }

  componentDidMount(){
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {

        this.setState({
          display: <li onClick={this.signOut}><Link to ="/">Sign Out</Link></li>
        })
        // User is signed in.
      } else {
        this.setState({
          display: <li><Link to ="/login">Login</Link></li>
        })
        // No user is signed in.
      }

    });
  }

  render(){





    // <li><Link to="/music">Music</Link></li>
    // <li><Link to="/bio">Bio</Link></li>
    // <li><Link to="/showcase">Showcase</Link></li>


    return(
      <div id="navbar">
        <nav>
          <div className="nav-wrapper" style={{backgroundColor: '#110032'}}>

            <ul id="nav-mobile" className="right">
            {this.state.display}





              <li><Link to ="/">Home</Link></li>
              {/* <li><Link to ="/music">Music</Link></li> */}
              <li><Link to ="/snakes">Snakes</Link></li>
              <li><Link to="/creatures">Creatures</Link></li>
              {/* <li><Link to ="/createpost">Create Post</Link></li> */}

              {/* <li><Link to="/feed">Feed</Link></li> */}
            </ul>
          </div>
        </nav>
      </div>
    )}
}

export default NavBar;
