import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Home from './pages/Home'
import CreatePost from './pages/CreatePost'
import Music from './pages/Music'
import Bio from './pages/Bio'
import Snakes from './pages/Snakes'
import Creatures from './pages/Creatures/Creatures'
import Feed from './pages/Feed'

import Posts from './pages/Posts'
import EditPost from './pages/EditPost'

import LoginPage from './pages/LoginPage'
import RegisterPage from './pages/RegisterPage'

import NavBar from './components/NavBar'

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <NavBar />

          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/createpost" component={CreatePost} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/music" component={Music} />
          {/* <Route path="/bio" component={Bio} /> */}
          <Route path="/feed" component={Feed} />
          <Route path="/snakes" component={Snakes} />
          <Route path="/posts/:id" component={Posts} />
          <Route path="/editpost/:id" component={EditPost} />
          <Route path="/creatures" component={Creatures} />


        </BrowserRouter>
      </div>
    );
  }
}

export default App;

// <NavBar />
