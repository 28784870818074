import React, { Component } from 'react'
import "./Posts.css"
import firebase from '../config/firebase'
import { withRouter } from 'react-router';
import BenMarkup from '../components/BenMarkup'
import { Redirect } from "react-router-dom";

class Posts extends Component {
  constructor(props){
    super(props)

    this.state={
      author: null,
      content: "",
      title: null,
      redirect: null,
      authorId: '',
      authorDisplay: null,
      postId: '',
      imgURL:null,
      displayImage:null,

      commentsDisplay: [],
      postComments: null,

      commentContent: null,

      currentUser: null,
      currentUserId: null,
    }
  }

  deletePost = (id) => {
    this.setState({
      ...this.state,
      redirect: <Redirect to={"/feed/"}/>,
    })

    firebase.firestore().collection("posts").doc(id).delete().then(function() {
      console.log("Post successfully deleted!");
    }).catch(function(error) {
    console.error("Error removing post: ", error);
    });

  }

  bumpPost = (id) => {
    let newDate = new Date().getTime()
    this.setState({
      ...this.state,
      redirect: <Redirect to={"/feed/"}/>,
    })

    firebase.firestore().collection("posts").doc(id).update({
      date: newDate
    })

  }

  addCommentsRender = (comments) => {
    for (let i = 0; i < comments.length; i++) {
      let tempContent = comments[i]['content']
      this.setState({
        ...this.state,
        commentsDisplay: [...this.state.commentsDisplay,
          <div key={comments[i]['date']}>
            <div className="CommentsContent">
              <p>{comments[i]['author']}</p>
              <BenMarkup string={tempContent} />

            </div>
            <hr/>
          </div>
        ]
      })
    }
  }


  changeCommentContent = (evt) => {
    this.setState({
      ...this.state,
      commentContent: (evt.target.value)
    })
  }

  postComment = () => {
    let newDate = new Date().getTime()
    if (this.state.commentContent && this.state.currentUser){
      firebase.firestore().collection("posts").doc(this.props.match.params.id).set({
        author: this.state.author,
        authorId: this.state.authorId,
        signature: this.state.signature,
        content: this.state.content,
        title: this.state.title,
        imgURL: this.state.imgURL,
        date: newDate,
        comments: [
          {
            author: this.state.currentUser,
            content: this.state.commentContent,
            date: newDate,
            commentorId: this.state.currentUserId,
          },
          ...this.state.postComments
        ]
      })
      .then( (docRef) => {
        window.location.reload()
        // this.setState({
        //   ...this.state,
        //   redirect: <Redirect to={"/feed/"}/>
        // })

      })
    }
  }

  componentDidMount(){
      firebase.firestore().collection('posts').doc(this.props.match.params.id).get().then( (doc) => {
      if (doc.exists) {
        this.setState({
          ...this.state,
          author: doc.data().author,
          authorId: doc.data().authorId,
          signature: doc.data().signature,
          content: doc.data().content,
          title: doc.data().title,
          date: doc.data().date,
          postId: doc.id,
          imgURL: doc.data().imgURL,
          postComments: doc.data().comments,
        })
        console.log(this.state.postComments)
        let comments = this.state.postComments
        this.addCommentsRender(comments)

        //Construct comments feeds



        if (this.state.imgURL){
          console.log(this.state.imgURL)
          let stringToSearch = this.state.imgURL
          console.log(stringToSearch.search(/.png/i))
          console.log(stringToSearch.search(/.jpg/i))
          console.log(stringToSearch.search(/.mp3/i))
          if (stringToSearch.search(/.png/i) > 0 ){
            this.setState({...this.state, displayImage:<img alt="user uploaded" width="400px" src={this.state.imgURL} />})
          }
          else if (stringToSearch.search(/.jpg/i) > 0 ){
            this.setState({...this.state, displayImage:<img alt="user uploaded" width="400px" src={this.state.imgURL} />})
          }
          else if (stringToSearch.search(/.mp3/i) > 0 ){
            console.log("audio should be on this page")
            this.setState({...this.state, displayImage:<audio controls src={this.state.imgURL} >Your browser doesn't support audio</audio>})
          }
        }

        firebase.auth().onAuthStateChanged((user) => {
          if (user){
            firebase.firestore().collection('users').doc(user.uid).get().then( (doc) => {
            if (doc.exists) {
              this.setState({...this.state,currentUser: doc.data().name,currentUserId: user.uid,})
              }
            })

            if (user.uid === this.state.authorId || user.uid === "lPqBE0qKs8cQbirVxjOnzT34fyj1") {
              this.setState({
                ...this.state,
                authorDisplay:
                <div>
                  <button className="btn blue" onClick={() => {this.setState({...this.state, redirect: <Redirect to={"/editpost/" + doc.id}/>,}) }}>Edit Post</button>
                  <button className="btn red" onClick={() => this.deletePost(doc.id)}>Delete Post</button>
                  <br/>
                  <button className="btn indigo" onClick={() => this.bumpPost(doc.id)}>BUUUUUUUUUUUUUUUUMP</button>
                  <hr />
                </div>
              })
            }
          }
        });
      }
    })
  }




  render() {
    return(

      <div style={{color:"#EDF5E1"}}>
        {this.state.redirect}
        <h1>{this.state.title}</h1>
        <BenMarkup string={this.state.content} />
        {this.state.displayImage}
        <br />
        <hr />
        <p>{this.state.author}</p>
        <p><i>"{this.state.signature}"</i></p>
        <button className="btn" onClick={() => {this.setState({...this.state, redirect: <Redirect to={"/feed/"}/>,}) }}>Back</button>
        {this.state.authorDisplay}
        <br />
        Comment: <textarea className="PostInput" type="text" placeholder="" onChange={this.changeCommentContent}></textarea>
        <button className="btn green" onClick={() => this.postComment()}>Post</button>
        {this.state.commentsDisplay}


      </div>
    )
  }
}

export default withRouter(Posts);
