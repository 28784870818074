import { runInThisContext } from "vm";
import { pathToFileURL } from "url";

const Particles = (p5) => {

  window.p5 = p5

  let particle_list = []

  let width = p5.windowWidth
  let height = p5.windowHeight/1.1

  p5.windowResized = () => {
    width = p5.windowWidth
    height = p5.windowHeight/1.1
    p5.resizeCanvas(width, height)
  }

  p5.setup = () => {

    p5.createCanvas(width, height)
    p5.frameRate(30)
    p5.noSmooth()  
  }

  let removeElement = (array, elem) => {  
    var index = array.indexOf(elem)
    if (index > -1) {
        array.splice(index, 1)
    }
  }

  let dist = (x1, y1, x2, y2) => {return Math.sqrt((x2-x1)**2 + (y2-y1)**2)}



  class Particle {

    
    constructor (given_x, given_y) {

      this.x = given_x || null
      this.y = given_y || null

      this.w = 5
      this.h = 5

      this.speed = Math.random() * 3 + 2

      this.radius = this.speed * 37

      // place a random point in the bounds of the canvas
      let x = Math.random()*width
      let y = Math.random()*height
  
      // get the closest wall on the x axis and y axis
      let diff_x = Math.min(Math.abs(0 - x), Math.abs(width - x));  
      let diff_y = Math.min(Math.abs(0 - y), Math.abs(height - y));

      // set the position of the point to the closest possible point on a wall
      (diff_x - diff_y > 0) ? this.x = x : this.y = y

      this.x = this.x || ((Math.abs(0 - x) < Math.abs(width - x)) ? this.x = 1-this.w : this.x = width-1);
      this.y = this.y || ((Math.abs(0 - y) < Math.abs(height - y)) ? this.y = 1-this.h : this.y = height-1);
      
      this.x = given_x || this.x
      this.y = given_y || this.y



      // assign each point a target 
      this.target_x = Math.random()*width
      this.target_y = Math.random()*height

      // get angle between the point and its target
      this.angle = Math.atan2((this.target_y - this.y), (this.target_x - this.x))




      // move points towards its target
      this.x_vel = this.speed * Math.cos(this.angle)
      this.y_vel = this.speed * Math.sin(this.angle)

      // this.target = // random (x, y)
    }
    update () {
      this.x += this.x_vel // *direction
      this.y += this.y_vel // * direction

      if (this.x > width || this.x < 0-this.w || this.y > height|| this.y < 0-this.h) {
        // particle_list.push(new Particle())
        // removeElement(particle_list, this)

        this.angle += Math.PI / 2
        this.x_vel = this.speed * Math.cos(this.angle)
        this.y_vel = this.speed * Math.sin(this.angle)
      }
    }

    draw (j) {
      p5.fill(255, 0, 0, 150)
      p5.ellipse(this.x, this.y, this.w, this.h)
      for (let i = 0; i < j; i++) {
        let diff = dist(this.x, this.y, particle_list[i].x, particle_list[i].y)
        if (diff < this.radius && diff !== 0) {
          p5.stroke(255, 0, 0, 600*(300/diff**1.6))
          p5.line(this.x, this.y, particle_list[i].x, particle_list[i].y)
        }
      }

    }
  }



  
  p5.draw = () => {
    p5.background('#020005')
    p5.textSize(32)
    p5.text('Home Pages Are Dying', width/2.4, height/2)

    for (let p = 0; p < particle_list.length; p++) {
      particle_list[p].update()
      particle_list[p].draw(p)
    }


  }

  p5.mouseClicked = () => {
    particle_list.push(new Particle(p5.mouseX, p5.mouseY))

  }

  for (let i = 0; i < 15; i++) {
    particle_list.push(new Particle(null, null))
  }
  

}
export default Particles
