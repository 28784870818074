import React, { Component } from 'react'
import "../index.css"
import Background from './background.jpg'

var musicStyle = {
  backgroundImage: "url(" + Background + ")",
  minHeight: "500px",
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",

  paddingBottom: "30px",

}

var commentParaStyle = {
  textAlign:"center",
  backgroundColor: '#111d',
  marginLeft: '9vh',
  marginRight: '9vh',
  paddingLeft: '2vh',
  paddingRight: '2vh',
  align: 'justify',
  marginTop: '-1px',
  paddingTop: "4px",
  paddingBottom: "4px",
}

class Music extends Component {


  render(){

    return(
      <div style={musicStyle}>


        <br />
        <iframe title={"1"} width="50%" height="500" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/614593293&color=%231a237e&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        <p style={commentParaStyle}>This was my first attempt at making something using all the new orchestral instruments I've obtained through Eastwest's Composer Cloud.
        I'm really happy with the quality of the libraries so far, but it has been a bit excruciating trying to generate exactly the sounds and articulations I want.
         This piece specifically was a sort of a cinematically aimless voyage through different moods of helplessness - quite reflective of my initial state with the library itself. The choral sections took some influence
          from some of Yuka Kitamura's work on the <i>Dark Souls III</i> soundtrack - which I think was most prevalent from 3:43 - 4:00. I'm also
         quite happy with how the section beginning 2:39 turned out. <br/> Expect much bigger and better orchestrated pieces in the future as I get to know the library.</p>


        <br />
        <hr />
        <br />
        <iframe title={"2"} width="50%" height="500" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/595989702&color=%23ffd600&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        <p style={commentParaStyle}>So I asked for suggestions/prompts and Alex told me to sample Mario. Basically every single line from Mario64 is instantly hilarious, and that's really all there is to say on the matter.</p>

        <br />
        <hr />
        <br />
        <iframe title={"3"} width="50%" height="500" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/571496235&color=%230edb44&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        <p style={commentParaStyle}>Combining electronic and orchestral stuff is something I really want to do, and this is a step towards doing that. I'm still not very confident in where and how the two should be integrated, and I think this is reflected in the piece as
        a conflict between two starkly opposed forces. From 4:12 onwards is where the 'pieces' really start to come together.
        I've taken great inspiration from Korean composer, M2U, specifically his album <i>Diversity</i> and the acoustic flourishes so beautifully prevailing in his work.</p>

        <br />
        <hr />
        <br />
        <iframe title={"4"} width="50%" height="500" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/494080026&color=%2342688f&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        <p style={commentParaStyle}>I made this ages ago but it was the first thing I ever actually 'finished' and felt proud of. I like to think I've improved a lot since then but it's still nice to look back on.</p>
      </div>
    )
  }
}


export default Music;
