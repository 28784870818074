import React, { Component } from 'react'
import "./Feed.css"
import firebase from '../config/firebase'
import { Redirect } from "react-router-dom";

let posts
class Feed extends Component {
  constructor(props){
    super(props)

    this.state={
      redirect: null,
      posts: [],
      postsDisplay: [],
    }
  }

//  VERY IMPORTANT FUNCTION --- determines the display order of posts. the number being indexed here is the element of the posts to sort by.
//  The elements corresponding to these numbers can be found in the componentDidMount, where the state 'posts' is populated with arrays
// alternatively, logging this.state.posts  will give you an idea of what each element involves. Currently sorted by the first element - date.
  sortPosts = (array) => {
    //console.log(array)
    function comparator(a, b) {
      if (a[1] < b[1]) return -1;
      if (a[1] > b[1]) return 1;
      return 0;
    }
    let sortedPosts = array
    sortedPosts = sortedPosts.sort(comparator)
    sortedPosts = sortedPosts.reverse()
    return sortedPosts
  }

  addPostRender = (postsSorted) => {
    for (let i = 0; i < postsSorted.length; i++) {
      this.setState({
        ...this.state,
        postsDisplay: [...this.state.postsDisplay,
          <div key={postsSorted[i][0]}>
            <div className="Content">
              <p onClick={() => this.redirector(postsSorted[i][0])}>
              <i>{postsSorted[i][2]}</i> <br/> <u>{postsSorted[i][3]}</u></p>
            </div>
            <hr/>
          </div>
        ]
      })
    }
  }

  componentDidMount(){
    posts = posts[0]
    this.unsubscribe = firebase.firestore().collection("posts").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            this.setState({
              ...this.state,
              posts: [...this.state.posts,
                [
                  doc.id,
                  doc.data().date,
                  doc.data().title,
                  doc.data().author,
                ]
              ]
            })
        });
        this.addPostRender(this.sortPosts(this.state.posts))
    });
  }
  componentWillUnmount(){
    this.unsubscribe = null;
  }

  redirector = (evt) => {
    this.setState({
      ...this.state,
      redirect: <Redirect to={"/posts/" + evt}/>
      })
  }

  render(){
    posts = [<div><p>hey</p></div>]
    return(
      <div>
        <h1>Posts</h1>
        <div className="PostContainer">
          {this.state.postsDisplay}
        </div>
        {this.state.redirect}
      </div>
    )
  }

}

export default Feed
