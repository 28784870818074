import React, { Component } from 'react'
import simulation_data from './SnakeFriend'
import P5Wrapper from 'react-p5-wrapper'
import SnakeFriend from './SnakeFriend'
import "../index.css"
import Slider from '@material-ui/core/Slider'
import Switch from '@material-ui/core/Switch'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

import createPlotlyComponent from 'react-plotly.js/factory';
const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);

let generation = 1

class Snakes extends Component {
  constructor(props){
    super(props)
    this.props = props
    this.state =
    {
      show_eyes: false,
      sim_speed: 1,
      controlled: false,
      mutation_rate: 0.1,
      simulation_data: [0],
      snake_dna: 0,
      autosave: false,
    }
    this.getData = this.getData.bind(this)

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.firestore().collection('snakes').doc(firebase.auth().currentUser.uid).get().then( (doc) => {
          if (doc.exists) {
            // console.log('got save file:')
            this.setState({
              ...this.state,
              snake_dna: doc.data().snake_dna,
            })
            
          }
        })
      }
    })
  }


  getData = (values) =>{ 
    this.setState({
      ...this.state,
      simulation_data: values,
      snake_dna: JSON.stringify(values[1])
    })
    generation += 1
    if (generation % 30 === 0 && this.state.autosave) {
      this.saveData()
      console.log('autosaving..')
    }
  }

  saveData = () => {
    if (firebase.auth().currentUser){
      // console.log("DO A THING")
      firebase.firestore().collection("snakes").doc(firebase.auth().currentUser.uid).set({
        snake_dna: this.state.snake_dna
      })
    }
  }

  render(){
    return(
      <div style={{ marginTop: '5px' }} >
        <P5Wrapper className="z-depth-5" sketch={SnakeFriend} snake_dna={this.state.snake_dna} sendData={this.getData} mutation_rate={this.state.mutation_rate} show_eyes={this.state.show_eyes} sim_speed={this.state.sim_speed} controlling = {this.state.controlled}></P5Wrapper>
      {/* <button className="btn waves-effect waves-light deep-purple darken-4 btn" onClick = {this.doStuff}>Show snake eyes</button> */}
        <div style={{ 'paddingLeft': '50px', 'display': 'inline-flex', 'width': '90%'}}>
          <div style={{ 'paddingLeft': '50px', 'width': '40%' }}>
            <p style={{'marginTop': '-7px'}}>Simulation speed: {this.state.sim_speed}</p>
            <Slider style={{'float':'right', 'marginTop': '-7px'}} marks={true} min={1} max={20} onChange = {(event, value) => {
              this.setState({
                ...this.state,
                sim_speed: value
              })
              // console.log(value)
            }}
            /> 
          </div>
          <div style={{ 'paddingLeft': '50px', 'width': '40%' }}> 
            <p style={{'marginTop': '-7px'}} >Mutation rate: {this.state.mutation_rate}</p>
              <Slider style={{'marginTop': '-7px'}} valueLabelFormat={'hey'} marks={true} min={0} max={1} step={0.05} defaultValue={0.15} onChange = {(event, value) => {
              this.setState({
                ...this.state,
                mutation_rate: value
              })
              // console.log(value)
            }}
            />            
          </div>
          
        </div>
        <div style={{ 'paddingLeft': '50px', 'display': 'inline-flex', 'width': '90%'}}>
          <div class="switch">
            <label>
              <input type="checkbox" onChange={(event) => {
                this.setState({
                  ...this.state,
                  controlled: event.target.checked
                })
              }} />
              <span class="lever"></span>
              Player Controls
              </label>
          </div>

          <div class="switch">
            <label>
              <input type="checkbox" onChange={(event) => {
                this.setState({
                  ...this.state,
                  show_eyes: event.target.checked
                })
              }} />
              <span class="lever"></span>
              Show Snake Eyes
              </label>
          </div>
          
          <div class="switch">
            <label>
              <input type="checkbox" onChange={(event) => {
                this.setState({
                  ...this.state,
                  autosave: event.target.checked
                })
              }} />
              <span class="lever"></span>
              Autosave
              </label>
          </div>

          <button className="btn" style = {{'marginLeft': '100px', 'marginBottom': '50px'}} onClick = {this.saveData}>Save Snakes</button>
        </div>

        <Plot
        data={[
          {
            x: Array.apply(null, { length: this.state.simulation_data[0].length }).map(Number.call, Number),
            y: this.state.simulation_data[0],
            type: 'scatter',
            mode: 'lines+points',
            marker: { color: 'red' },
          },
            { type: 'bar', x: Array.apply(null, { length: this.state.simulation_data[0].length }).map(Number.call, Number), y: this.state.simulation_data[0] },
        ]}
        layout={{ width: 800, height: 600, title: 'Average Fitness vs Generation' }}
      />
          
      </div>
    )
  }
}


export default Snakes;
