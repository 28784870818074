import React, { Component } from 'react'
import './CreatePost.css'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import FileUploader from 'react-firebase-file-uploader';
import { Redirect } from "react-router-dom";


var curUser = null


class CreatePost extends Component{
  constructor(props){
    super(props)
    this.props = props
    this.state =
    {
      author: 'anon',
      signature: 'hmmm',
      title: "Untitled",
      content: "",
      redirect: null,

      image: '',
      isUploading: false,
      progress: 0,
      imageURL: ''
    }

  }


  componentDidMount(){
    curUser = firebase.auth().currentUser
    if (!curUser){
      alert("You gotta be logged in to do that")
      this.setState({
        ...this.state,
        redirect: <Redirect to="/"/>
      })
    }

    if (curUser){
      firebase.firestore().collection('users').doc(curUser.uid).get().then( (doc) => {
      if (doc.exists) {
        this.setState({
          ...this.state,
          author: doc.data().name,
          authorId: curUser.uid,
          signature: doc.data().quote,
          })
        }
      })
    }
  }

  changeTitle = (evt) => {
    this.setState({
      ...this.state,
      title: (evt.target.value)
    })
  }

  changeContent = (evt) => {
    this.setState({
      ...this.state,
      content: (evt.target.value)
    })
  }

  handleUploadStart = () => this.setState({isUploading: true, progress: 0});

  handleProgress = (progress) => this.setState({progress});

  handleUploadError = (error) => {
    this.setState({isUploading: false});
    console.error(error);
  }

  handleUploadSuccess = (filename) => {
    this.setState({image: filename, progress: 100, isUploading: false});
    firebase.storage().ref('images').child(filename).getDownloadURL().then(url => this.setState({imageURL: url}));
  };


  createPost = (evt) => {
    let newDate = new Date().getTime()
    firebase.firestore().collection("posts").add({
      author: this.state.author,
      authorId: this.state.authorId,
      signature: this.state.signature,
      content: this.state.content,
      title: this.state.title,
      date: (newDate)? newDate : 1,
      imgURL: this.state.imageURL,
      comments: [],
    })
    .then( (docRef) => {
      this.setState({
        ...this.state,
        redirect: <Redirect to={"/posts/" + docRef.id}/>
      })

    })

  }


  render() {
    return (
      <div align="Center">
        <div className="PostContainer">
          {this.state.redirect}
          Post Title: <input className="PostInput" type="text" placeholder="___" onChange={this.changeTitle}></input>
          <br/>
          Post Content: <textarea className="PostInput" type="text" placeholder="" onChange={this.changeContent}></textarea>
          <br/>
          <div className="UploadContainer">
            <form>
            {this.state.isUploading &&
            <p>Progress: {this.state.progress}</p>
            }
            {this.state.imageURL &&
            <img width="400px" src={this.state.imageURL} alt="user submitted" />
            }
            <FileUploader
            accept="*/*"
            name="image"
            randomizeFilename
            storageRef={firebase.storage().ref('images')}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
            />
            </form>

          </div>
          <br/>
          <button className="btn" onClick = {this.createPost}>Create Post</button>
          <br/>
          <p>Formatting guide:<br/>
          ##bold text## = <b>bold text</b><br/>
          (  ////  ) - four /'s - produces a linebreak<br/>
          Any number of "|" (vertical line / pipe) will produce a space. Used for multiple spaces/tabs.


          </p>
        </div>


      </div>

    )
  }


}
export default CreatePost;
