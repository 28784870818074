import firebase from 'firebase/app'

// Initialize Firebase
const config = {
  apiKey: "AIzaSyC8uH941CKuzW6CRnBUyI_yyBaMi9_mHJQ",
  authDomain: "ben-braham-website.firebaseapp.com",
  databaseURL: "https://ben-braham-website.firebaseio.com",
  projectId: "ben-braham-website",
  storageBucket: "ben-braham-website.appspot.com",
  messagingSenderId: "1030428648218"
};
firebase.initializeApp(config);


export default firebase
