import React, { Component } from 'react'
import "../index.css"


class Bio extends Component {


  render(){

    return(
      <div>
        <h1>Biography</h1>
        <p>
          Hey, I'm Ben.<br />I've studied music as a clasically trained pianist for 12 years, and have been composing my own music for 4 years now.<br /><br />I'm currently studying Computer Science and Physics at The University of Sydney.
        </p>
      </div>
    )
  }
}


export default Bio;
