import React, { Component } from 'react'
import P5Wrapper from 'react-p5-wrapper'
import CreatureSim from './CreatureSim'
import "../../index.css"
import Slider from '@material-ui/core/Slider'
import Switch from '@material-ui/core/Switch'

import createPlotlyComponent from 'react-plotly.js/factory';
const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);


class Creatures extends Component {
  constructor(props) {
    super(props)
    this.state =
      {

      }

  }


  render() {
    return (
      <div style={{ marginTop: '5px' }} >
        <P5Wrapper className="z-depth-5" sketch={CreatureSim}></P5Wrapper>
      </div>
    )
  }
}


export default Creatures;
