import React, { Component } from 'react'



var bold = {
  replacee: "##",
  replacer1: "<b>",
  replacer2: "</b>"
}

var linebreak = {
  replacee: "//",
  replacer1: "<br />",
  replacer2: ""
}

var tab = {
  replacee: "|",
  replacer1: "&nbsp;&nbsp;",
  replacer2: ""
}



var replacements = [bold, linebreak, tab]




class BenMarkup extends Component {
  render() {
    var str = this.props.string
    var re = new RegExp("<", 'g')
    var count = Math.floor((str.match(re) || []).length / 2);
    for (let i=0; i<count; i++){
      str = str.replace("<", "")
      str = str.replace(">", "")
    }

    for (let i = 0, len=replacements.length; i < len; i++){
      var replacee = replacements[i].replacee
      var replacer1 = replacements[i].replacer1
      var replacer2 = replacements[i].replacer2

      re = new RegExp(replacee, 'g')
      count = Math.floor((str.match(re) || []).length / 2);
      for (let i=0; i<count; i++){
        str = str.replace(replacee, replacer1)
        str = str.replace(replacee, replacer2)
      }
    }

    return(<div className="PostInput" dangerouslySetInnerHTML={{ __html: str}} />)
  }
}

export default BenMarkup
