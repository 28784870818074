import React, { Component } from 'react'
import 'firebase/firestore'
import 'firebase/auth'
import './CreatePost.css'
import firebase from 'firebase/app'
import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
var curUser = null

class EditPost extends Component{
  constructor(props){
    super(props)
    this.props = props
    this.state =
    {
      author: 'anon',
      authorId: '',
      signature: 'hmmm',
      title: "Untitled",
      content: "",
      redirect: null,
      imgURL: '',
      postComments: [],
      postDate: 1,
    }

  }
  componentDidMount(){
    firebase.firestore().collection('posts').doc(this.props.match.params.id).get().then( (doc) => {
    if (doc.exists) {
      this.setState({
        ...this.state,
        author: doc.data().author,
        authorId: doc.data().authorId,
        signature: doc.data().signature,
        content: doc.data().content,
        title: doc.data().title,
        docId: doc.id,
        imgURL: doc.data().imgURL,
        postComments: doc.data().comments,
        postDate: doc.data().date,
      })
      curUser = firebase.auth().currentUser
      if (curUser && curUser.uid !== this.state.authorId){
        alert("This isn't your post... Idiot!")
        this.setState({
          ...this.state,
          redirect: <Redirect to="/"/>
        })
      }
    }
  })


  }

  changeTitle = (evt) => {
    this.setState({
      ...this.state,
      title: (evt.target.value)
    })
  }

  changeContent = (evt) => {
    this.setState({
      ...this.state,
      content: (evt.target.value)
    })
  }


  updatePost = (evt) => {
    let redirectId = this.state.docId
    firebase.firestore().collection("posts").doc(this.state.docId).set({
      author: this.state.author,
      authorId: this.state.authorId,
      signature: this.state.signature,
      content: this.state.content,
      title: this.state.title,
      imgURL: this.state.imgURL,
      comments: this.state.postComments,
      date: this.state.postDate,
    })
    .then( (docRef) => {
      this.setState({
        ...this.state,
        redirect: <Redirect to={"/posts/" + redirectId}/>
      })

    })

  }

  render() {
    return (
      <div align="Center">
        <div className="PostContainer">
          {this.state.redirect}
          Post Title: <input className="PostInput" type="text" value={this.state.title} onChange={this.changeTitle}></input>
          <br/>
          Post Content: <textarea className="PostInput" type="text" value={this.state.content} onChange={this.changeContent}></textarea>
          <br/>
          <br/>
          <button className="btn" onClick = {this.updatePost}>Update Post</button>
          <br/>
          <p>Formatting guide:<br/>
          ##bold text## = <b>bold text</b><br/>
          (  ////  ) - four /'s - produces a linebreak<br/>
          Any number of "|" (vertical line / pipe) will produce a space. Used for multiple spaces/tabs.

          </p>
        </div>
      </div>
    )
  }
}
export default withRouter(EditPost);
